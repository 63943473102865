import { inject, Injectable } from "@angular/core";
import { textPage } from "@root/shared/utilities/text-editor.utilties";
import { Observable } from "rxjs";
import { CustomerPageCase } from "../enums/customer-page-case.enum";
import { CustomerPageSubCase } from "../enums/customer-page-subcase.enum";
import { CustomerPage } from "../enums/customer-page.enum";
import { ErrorCase } from "../enums/error-case.enum";
import { ICustomerErrorPageText } from "../models/customer-error-page-text.model";
import { ICustomerPageText } from "../models/customer-page-text.model";
import { CustomerPageRepository } from "../repositories/customer-page.repository";

@Injectable({
  providedIn: "root",
})
export class CustomerPageService {
  #customerPageRepository = inject(CustomerPageRepository);

  getCustomerPageText(
    page: CustomerPage,
    pageCase: CustomerPageCase,
    pageSubCase: CustomerPageSubCase,
  ): Observable<ICustomerPageText> {
    return this.#customerPageRepository.getCustomerPageText(page, pageCase, pageSubCase);
  }

  insertCustomerPageText(customerPageText: any): Observable<void> {
    const textFormData = new FormData();
    const pageText = textPage(textFormData, customerPageText);
    return this.#customerPageRepository.insertCustomerPageText(pageText);
  }

  getCustomerPageErrorText(pageCase: ErrorCase, pageSubCase: CustomerPageSubCase): Observable<ICustomerErrorPageText> {
    return this.#customerPageRepository.getCustomerPageErrorText(pageCase, pageSubCase);
  }

  insertCustomerPageErrorText(customerPageErrorText: any): Observable<void> {
    const textFormData = new FormData();
    const pageText = textPage(textFormData, customerPageErrorText);
    return this.#customerPageRepository.insertCustomerPageErrorText(pageText);
  }
}
