<!-- confirm appointment -->
<est-small-header
  heading="CONFIRM_APPOINTMENT"
  appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
  subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
  tooltip="CONFIRM_APPOINTMENT"
  headingClass="!text-base"
></est-small-header>

<div class="my-3" id="text-Accordion">
  <mat-accordion>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
      appliedClasses="!mt-6"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ConfirmAppointment, subCase.GeneralOne)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
      appliedClasses="!mb-6 !mt-2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ConfirmAppointment, subCase.GeneralTwo)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Re-confirm appointment -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.RE_CONFIRM_APPOINTMENT"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
      headingClass="!text-base"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
      appliedClasses="!mt-6 !mb-2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ReconfirmAppointment, subCase.GeneralOne)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
      appliedClasses="!mb-6"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ReconfirmAppointment, subCase.GeneralTwo)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Confirm interest -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.CONFIRM_INTEREST"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
      headingClass="!text-base"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      appliedClasses="!mt-6"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ConfirmInterest, subCase.GeneralOne)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.ConfirmInterest, subCase.GeneralTwo)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!my-2 !mb-6"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />
    <!-- Not interested -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.NOT_INTERESTED"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      headingClass="!text-base"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.NotInterested, subCase.GeneralOne)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!mt-6 !mb-2"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.NotInterested, subCase.GeneralTwo)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!mb-6"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Cancel appointment -->
    <est-small-header
      heading="CANCEL_APPOINTMENT"
      appliedClasses="!mt-10 !mb-2 !text-sm "
      headingClass="!text-base"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
      appliedClasses="!mt-6 !mb-2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.CancelAppointment, subCase.GeneralOne)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
      (openCasePage)="getAccordionData(page.ConfirmationPage, pageCase.CancelAppointment, subCase.GeneralTwo)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
  </mat-accordion>
</div>
