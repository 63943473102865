import { AbstractControl, FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { ICreateEditPropertyTextPayload } from "@root/data/market/properties/models/create-edit-property-text-payload.model";
import { ITextFieldGroup } from "@root/views/main/property/property-marketing/components/text-field-form-group.interface";
import { ITextEditorFormGroup, ITextEditorImage, TextEditorImageFormGroup } from "../interfaces/text-editor.interface";

export const constructTextEditorFormGroup = (fb: NonNullableFormBuilder): FormGroup<ITextEditorFormGroup> => {
  return fb.group({
    value: fb.control<string>(""),
    images: fb.array<FormGroup<TextEditorImageFormGroup>>([]),
  });
};

export const constructTextEditorImageFormGroup = (
  fb: NonNullableFormBuilder,
  textImage: ITextEditorImage,
): FormGroup<TextEditorImageFormGroup> => {
  return fb.group({
    file: fb.control(textImage.file),
    key: fb.control(textImage.key),
  });
};

export const buildPropertyTextFormData = (payload: Partial<ICreateEditPropertyTextPayload>): FormData => {
  const formData = new FormData();
  return buildFormData(formData, payload);
};

export const buildFormData = (formData: FormData, data: any, parentKey: string | null = null): FormData => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      const objectKey = data instanceof Array ? `${parentKey}[${key}]` : `${parentKey}.${key}`;
      buildFormData(formData, data[key], parentKey ? objectKey : key);
    });
  } else if (parentKey) {
    const value = data ?? "";
    formData.append(parentKey, value);
  }
  return formData;
};

export const constructTextFieldsFormGroup = (
  fb: NonNullableFormBuilder,
  languageId: string,
  isHeadlineDisabled: boolean = true,
): FormGroup<ITextFieldGroup> => {
  return fb.group({
    isEnabled: fb.control<boolean>(false),
    isHeadlineEnabled: fb.control<boolean>(false),
    languageId: fb.control<string>(languageId),
    headline: fb.control<string>({ value: "", disabled: isHeadlineDisabled }, Validators.required),
    text: constructTextEditorFormGroup(fb),
  });
};

export const textPage = (formData: FormData, formGroup: FormGroup): FormData => {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormControl) {
      formData.append(key, control.value);
    } else if (control instanceof FormArray) {
      getTextFieldLanguages(formData, control as FormArray, key);
    }
  });
  return formData;
};

const getTextFieldLanguages = (formData: FormData, formArray: FormArray, controlName: string): FormData => {
  formArray.controls.forEach((element: AbstractControl) => {
    if (element.dirty) {
      // Check if the array element has changes
      const elementGroup = element as FormGroup;
      Object.keys(elementGroup.controls).forEach((key) => {
        const controlKey = elementGroup.get(key);
        if (controlKey instanceof FormControl) {
          formData.append(`${controlName}.${key}`, controlKey.value);
        } else if (controlKey instanceof FormGroup) {
          getTextBoxWithImages(formData, controlKey, `${controlName}.${key}`);
        }
      });
    }
  });
  return formData;
};

const getTextBoxWithImages = (formData: FormData, formGroup: FormGroup, nameOfFiled: string): FormData => {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormControl) {
      formData.append(`${nameOfFiled}.${key}`, control.value);
    } else if (control instanceof FormArray) {
      getImageValue(formData, control as FormArray, `${nameOfFiled}.${key}`);
    }
  });
  return formData;
};

const getImageValue = (formData: FormData, formArray: FormArray, nameOfFiled: string) => {
  formArray.controls.forEach((element: any) => {
    Object.keys(element.controls as FormGroup).forEach((key) => {
      const controlKey = element.get(key);
      formData.append(`${nameOfFiled}.${key}`, controlKey.value);
    });
  });
  return formData;
};
