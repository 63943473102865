<!-- confirm appointment -->
<est-small-header
  heading="CONFIRM_APPOINTMENT"
  appliedClasses="!mt-10 !mb-2 !text-base !font-bold"
  subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
  tooltip="CONFIRM_APPOINTMENT"
  headingClass="!text-base"
></est-small-header>

<div class="my-3" id="text-Accordion">
  <mat-accordion>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.NO_TIME_SLOT_ON_THE_APPOINTMENT"
      appliedClasses="!mt-6"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ConfirmAppointment, subCase.NoTimeSlot)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      (changeText)="editDate()"
    ></est-accordion-editor>
    <est-accordion-editor
      (changeText)="editDate()"
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.WITH_TIME_SLOT_ON_THE_APPOINTMENT"
      appliedClasses="!mb-6 !mt-2"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ConfirmAppointment, subCase.WithTimeSlot)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Re-confirm appointment -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.RE_CONFIRM_APPOINTMENT"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
      headingClass="!text-base"
    ></est-small-header>
    <est-accordion-editor
      (changeText)="editDate()"
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.WITH_AND_WITHOUT_TIME_SLOT_ON_THE_APPOINTMENT"
      appliedClasses="!my-6"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ReconfirmAppointment, subCase.AnyTimeSlot)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Confirm interest -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.CONFIRM_INTEREST"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
      headingClass="!text-base"
    ></est-small-header>
    <est-accordion-editor
      (changeText)="editDate()"
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      appliedClasses="!mt-6"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.WITH_RELATION_TO_A_UNIT"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ConfirmInterest, subCase.WithUnit)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
    <est-accordion-editor
      (changeText)="editDate()"
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.WITHOUT_RELATION_TO_A_UNIT"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ConfirmInterest, subCase.NoUnit)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!my-2"
    ></est-accordion-editor>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.SELECT_UNITS_EXPLANATION"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.ConfirmInterest, subCase.SelectUnit)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!mb-6"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />
    <!-- Not interested -->
    <est-small-header
      heading="TEXT_PAGE.WELCOME_PAGE.NOT_INTERESTED"
      appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
      headingClass="!text-base"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="TEXT_PAGE.WELCOME_PAGE.DECLINE_INTEREST"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.NotInterested, subCase.DeclineInterest)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
      appliedClasses="!my-6"
    ></est-accordion-editor>

    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- Cancel appointment -->
    <est-small-header
      heading="CANCEL_APPOINTMENT"
      appliedClasses="!mt-10 !mb-2 !text-sm "
      headingClass="!text-base"
      subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
      tooltip="CONFIRM_APPOINTMENT"
    ></est-small-header>
    <est-accordion-editor
      [systemLanguageConfiguration]="systemLanguageConfiguration"
      (changeText)="editDate()"
      panelHeader="CANCEL_APPOINTMENT"
      appliedClasses="!mt-6"
      (openCasePage)="getAccordionData(page.WelcomePage, pageCase.CancelAppointment, subCase.CancelAppointment)"
      [textFieldFormArray]="customerPageTextsFormControls.formGroup.controls.textFieldLanguage"
      [isLoading]="isLoading"
    ></est-accordion-editor>
  </mat-accordion>
</div>
