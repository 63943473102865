import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { Observable } from "rxjs/internal/Observable";
import { CustomerPageCase } from "../enums/customer-page-case.enum";
import { CustomerPageSubCase } from "../enums/customer-page-subcase.enum";
import { CustomerPage } from "../enums/customer-page.enum";
import { ErrorCase } from "../enums/error-case.enum";
import { ICustomerErrorPageText } from "../models/customer-error-page-text.model";
import { ICustomerPageText } from "../models/customer-page-text.model";

@Injectable({ providedIn: "root" })
export class CustomerPageRepository {
  readonly #http = inject(HttpClient);

  getCustomerPageText(
    page: CustomerPage,
    pageCase: CustomerPageCase,
    pageSubCase: CustomerPageSubCase,
  ): Observable<ICustomerPageText> {
    return this.#http.get<ICustomerPageText>(APIS.organisation.getCustomerPageText, {
      params: new HttpParams({
        fromObject: {
          page: page,
          pageCase: pageCase,
          pageSubcase: pageSubCase,
        },
      }),
    });
  }

  insertCustomerPageText(pageText: FormData): Observable<void> {
    return this.#http.put<void>(APIS.organisation.insertCustomerPageText, pageText);
  }

  getCustomerPageErrorText(pageCase: ErrorCase, pageSubCase: CustomerPageSubCase): Observable<ICustomerErrorPageText> {
    return this.#http.get<ICustomerErrorPageText>(APIS.organisation.getCustomerPageErrorText, {
      params: new HttpParams({
        fromObject: {
          case: pageCase,
          subCase: pageSubCase,
        },
      }),
    });
  }

  insertCustomerPageErrorText(errorPageText: FormData): Observable<void> {
    return this.#http.put<void>(APIS.organisation.insertCustomerPageErrorText, errorPageText);
  }
}
