export enum CustomerPageSubCase {
  NoTimeSlot = "NoTimeSlot",
  WithTimeSlot = "WithTimeSlot",
  AnyTimeSlot = "AnyTimeSlot",
  WithUnit = "WithUnit",
  NoUnit = "NoUnit",
  SelectUnit = "SelectUnit",
  DeclineInterest = "DeclineInterest",
  CancelAppointment = "CancelAppointment",
  GeneralOne = "GeneralOne",
  GeneralTwo = "GeneralTwo",
}
