<div id="text-Accordion">
  <mat-accordion>
    @for (errorCase of errorCasesList; track $index) {
      <est-small-header
        appliedClasses="!mt-10 !mb-2 !text-sm !font-bold"
        [heading]="convertToUpperSnakeCase(errorCase)"
        [tooltip]="convertToUpperSnakeCase(errorCase)"
        subheading="TEXT_PAGE.WELCOME_PAGE.FILL_IN_TEXTS_BELOW_IN_THE_REQUIRED_LANGUAGE_VERSIONS"
        headingClass="!text-base"
      ></est-small-header>
      <est-accordion-editor
        [systemLanguageConfiguration]="systemLanguageConfiguration"
        (changeText)="editDate()"
        panelHeader="GENERAL_TEXT_PLACEHOLDER_1"
        appliedClasses="!mt-6"
        (openCasePage)="getAccordionData(errorCase, generalTextOne)"
        [textFieldFormArray]="customerPageErrorTextsFormControls.formGroup.controls.textFieldLanguage"
        [isLoading]="isLoading"
      ></est-accordion-editor>
      <est-accordion-editor
        [systemLanguageConfiguration]="systemLanguageConfiguration"
        (changeText)="editDate()"
        panelHeader="GENERAL_TEXT_PLACEHOLDER_2"
        appliedClasses="!mb-6 !mt-2"
        (openCasePage)="getAccordionData(errorCase, generalTextTwo)"
        [textFieldFormArray]="customerPageErrorTextsFormControls.formGroup.controls.textFieldLanguage"
        [isLoading]="isLoading"
      ></est-accordion-editor>
      @if (!$last) {
        <hr class="border-black-100 absolute start-0 end-0" />
      }
    }
  </mat-accordion>
</div>
