export enum ErrorCase {
  AppointmentFullyBooked = "AppointmentFullyBooked",
  AppointmentConfirmedOrReconfirmed = "AppointmentConfirmedOrReconfirmed",
  AppointmentCancelled = "AppointmentCancelled",
  DateOfEventHasPassedAllInvitations = "DateOfEventHasPassedAllInvitations",
  DateOfEventHasPassedReconfirm = "DateOfEventHasPassedReconfirm",
  DateOfPrivateViewingHasPassedAllInvitations = "DateOfPrivateViewingHasPassedAllInvitations",
  DateOfPrivateViewingHasPassedReconfirm = "DateOfPrivateViewingHasPassedReconfirm",
  EventCancelledOrDeletedAllInvitations = "EventCancelledOrDeletedAllInvitations",
  EventCancelledOrDeletedReconfirm = "EventCancelledOrDeletedReconfirm",
  PrivateViewingCancelledOrDeletedAllInvitations = "PrivateViewingCancelledOrDeletedAllInvitations",
  PrivateViewingCancelledOrDeletedReconfirm = "PrivateViewingCancelledOrDeletedReconfirm",
  InterestAlreadyConfirmed = "InterestAlreadyConfirmed",
  InterestIsAlreadyDeclined = "InterestIsAlreadyDeclined",
  AppointmentRequestReceived = "AppointmentRequestReceived",
  AppointmentRequestCancelled = "AppointmentRequestCancelled",
}
