import { Component, inject, OnInit } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { TranslateModule } from "@ngx-translate/core";
import { CustomerPageSubCase } from "@root/data/market/customer-page/enums/customer-page-subcase.enum";
import { ErrorCase } from "@root/data/market/customer-page/enums/error-case.enum";
import { CustomerPageService } from "@root/data/market/customer-page/services/customer-page.service";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { CustomerPageTextEditorComponent } from "@root/shared/accordion-editor/accordion-editor.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { finalize } from "rxjs/internal/operators/finalize";
import { CustomerPageErrorTextsFormControls } from "../customer-page-error-texts.form-controls";

@Component({
  selector: "est-error-page",
  standalone: true,
  imports: [
    MatAccordion,
    SmallHeaderComponent,
    TranslateModule,
    SmallHeaderComponent,
    CustomerPageTextEditorComponent,
    TranslateModule,
  ],
  templateUrl: "./error-page.component.html",
  styleUrl: "./error-page.component.scss",
  providers: [CustomerPageErrorTextsFormControls],
})
export class ErrorPageComponent implements OnInit {
  errorCasesList = Object.values(ErrorCase);
  customerPageErrorTextsFormControls = inject(CustomerPageErrorTextsFormControls);
  customerPageService = inject(CustomerPageService);
  systemConfigurationService = inject(SystemConfigurationsService);
  isLoading: boolean = true;
  openCasePage: any;
  panelOpenState: any;
  systemLanguageConfiguration!: ISystemLanguageConfiguration;

  languagesIds: string[] = [];
  generalTextOne = CustomerPageSubCase.GeneralOne;
  generalTextTwo = CustomerPageSubCase.GeneralTwo;

  ngOnInit(): void {
    this.#getSystemLanguage();
  }

  convertToUpperSnakeCase(input: string): string {
    return "TEXT_PAGE.ERROR_PAGE." + input.replace(/([a-z])([A-Z])/g, "$1_$2").toUpperCase();
  }

  getAccordionData(pageCase: ErrorCase, pageSubCase: CustomerPageSubCase) {
    this.isLoading = true;
    this.customerPageService
      .getCustomerPageErrorText(pageCase, pageSubCase)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.customerPageErrorTextsFormControls.formGroup.patchValue(data);
          const textArray = data && data.textFieldLanguages;
          this.customerPageErrorTextsFormControls.formGroup.controls.textFieldLanguage.clear();
          if (data) {
            textArray &&
              textArray.forEach((textField) => {
                const textFieldFormGroup =
                  this.customerPageErrorTextsFormControls.constructTextFieldsDataFormGroup(textField);
                this.customerPageErrorTextsFormControls.formGroup.controls.textFieldLanguage.push(textFieldFormGroup);
              });
          } else {
            this.languagesIds.forEach((newLang) => {
              this.customerPageErrorTextsFormControls.formGroup.controls.textFieldLanguage.push(
                this.customerPageErrorTextsFormControls.constructLangForTextFieldsDataFormGroup(newLang),
              );
            });
          }
        },
      });
  }

  editDate() {
    this.customerPageService.insertCustomerPageErrorText(this.customerPageErrorTextsFormControls.formGroup).subscribe();
  }

  #getSystemLanguage() {
    this.systemConfigurationService.getLanguageSystemConfiguration().subscribe((languageConfiguration) => {
      this.systemLanguageConfiguration = languageConfiguration;
    });
  }
}
