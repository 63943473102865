<mat-expansion-panel
  [ngClass]="appliedClasses()"
  hideToggle="true"
  class="!rounded-lg"
  (opened)="openCasePage.emit(); panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header>
    <div class="flex justify-between items-center">
      <p class="font-medium text-sm">{{ panelHeader() | translate }}</p>
      <mat-icon class="text-3xl pb-8">
        @if (!panelOpenState) {
          expand_more
        } @else {
          expand_less
        }
      </mat-icon>
    </div>
  </mat-expansion-panel-header>
  <div class="relative">
    @if (isLoading()) {
      <div class="h-52">
        <est-loading-overlay></est-loading-overlay>
      </div>
    }
    @for (textGroup of textFieldFormArray().controls; track textGroup) {
      @if (textFieldFormArray().length > 0) {
        <hr class="border-black-200 absolute start-0 end-0" />
      }
      <form (change)="emitValue()" [formGroup]="textGroup">
        <div class="py-4">
          <div class="mb-6 flex justify-between">
            <div>
              <est-small-header
                appliedClasses="mb-2"
                heading="PROPERTY_TEXTS.HEADLINE"
                subheading="PROPERTY_TEXTS.HEADLINE_DESCRIPTION"
                subheadingAppliedClasses="!max-w-[85%]"
                tooltip="PROPERTY_TEXTS.HEADLINE"
              ></est-small-header>
              <est-input
                [control]="textGroup.controls.headline"
                appliedClass="mt-5 w-full xl:w-[37.5rem]"
                label="PROPERTY_TEXTS.HEADLINE"
              ></est-input>
            </div>
            <div>
              @if (textGroup.controls.languageId.value) {
                <div class="flex gap-2 items-center justify-end">
                  <img
                    class="w-6 h-4 rounded-sm"
                    [src]="
                      textGroup.controls.languageId.value &&
                      getDefaultEndUserLanguage(textGroup.controls.languageId.value)?.flagImageUrl
                    "
                    [alt]="'FLAG' | translate"
                  />
                  <span class="font-medium text-sm">{{
                    textGroup.controls.languageId.value &&
                      getDefaultEndUserLanguage(textGroup.controls.languageId.value)?.name
                  }}</span>
                </div>
              }

              @if (
                textGroup.controls.languageId.value &&
                systemLanguageConfiguration() &&
                systemLanguageConfiguration().defaultEndUserLanguage.languageId === textGroup.controls.languageId.value
              ) {
                <p class="text-xs font-light text-black-500">
                  {{ "PROPERTY_TEXTS.DEFAULT_LANGUAGE_END_USER" | translate }}
                </p>
              }
            </div>
          </div>
          <div>
            <est-small-header
              appliedClasses="mb-2"
              heading="PROPERTY_TEXTS.TEXT"
              subheading="PROPERTY_TEXTS.TEXT_DESCRIPTION"
              subheadingAppliedClasses="!max-w-[85%]"
              tooltip="PROPERTY_TEXTS.TEXT"
            ></est-small-header>
            <est-ck-text-editor
              [textControl]="textGroup.controls.text"
              (textFieldOutput)="emitValue()"
            ></est-ck-text-editor>
          </div>
        </div>
      </form>
    }
  </div>
</mat-expansion-panel>
