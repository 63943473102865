import { Component, input, output } from "@angular/core";
import { FormArray, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionPanel, MatExpansionPanelHeader } from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { CkTextEditorComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/ck-text-editor/ck-text-editor.component";

import { NgClass } from "@angular/common";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { ITextFieldGroup } from "@root/views/main/property/property-marketing/components/text-field-form-group.interface";
import { ILanguage } from "../interfaces/language.interface";
import { LoadingOverlayComponent } from "../loading-overlay/loading-overlay.component";

@Component({
  selector: "est-accordion-editor",
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    SmallHeaderComponent,
    TranslateModule,
    InputComponent,
    ReactiveFormsModule,
    CkTextEditorComponent,
    MatIcon,
    NgClass,
    LoadingOverlayComponent,
  ],
  templateUrl: "./accordion-editor.component.html",
  styleUrl: "./accordion-editor.component.scss",
})
export class CustomerPageTextEditorComponent {
  textFieldFormArray = input.required<FormArray<FormGroup<ITextFieldGroup>>>();
  panelOpenState = false;
  panelHeader = input.required<string>();
  appliedClasses = input<string>();
  openCasePage = output<void>();
  isLoading = input(true);
  systemLanguageConfiguration = input.required<ISystemLanguageConfiguration>();
  changeText = output();

  getDefaultEndUserLanguage(languageId: string): ILanguage | undefined {
    if (
      this.systemLanguageConfiguration() &&
      this.systemLanguageConfiguration().defaultEndUserLanguage.languageId === languageId
    ) {
      return this.systemLanguageConfiguration().defaultEndUserLanguage;
    } else {
      const foundLanguage =
        this.systemLanguageConfiguration() &&
        this.systemLanguageConfiguration().allowedLanguages.find((value) => value.language.languageId === languageId);

      return foundLanguage ? foundLanguage.language : undefined;
    }
  }

  emitValue() {
    this.changeText.emit();
  }
}
