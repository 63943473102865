import { inject } from "@angular/core";
import { CustomerPageCase } from "@root/data/market/customer-page/enums/customer-page-case.enum";
import { CustomerPageSubCase } from "@root/data/market/customer-page/enums/customer-page-subcase.enum";
import { CustomerPage } from "@root/data/market/customer-page/enums/customer-page.enum";
import { CustomerPageService } from "@root/data/market/customer-page/services/customer-page.service";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { CustomerPageTextsFormControls } from "@root/views/main/organization/organization-system-modules/customer-page/text/customer-page-texts.form-controls";
import { finalize } from "rxjs/internal/operators/finalize";

export abstract class TextPageAbstract {
  customerPageTextsFormControls = inject(CustomerPageTextsFormControls);
  customerPageService = inject(CustomerPageService);
  systemConfigurationService = inject(SystemConfigurationsService);
  pageCase = CustomerPageCase;
  subCase = CustomerPageSubCase;
  page = CustomerPage;
  isLoading: boolean = true;
  openCasePage: any;
  panelOpenState: any;
  systemLanguageConfiguration!: ISystemLanguageConfiguration;

  languagesIds: string[] = [];

  editDate() {
    this.customerPageService.insertCustomerPageText(this.customerPageTextsFormControls.formGroup).subscribe();
  }

  getAccordionData(page: CustomerPage, pageCase: CustomerPageCase, subCase: CustomerPageSubCase) {
    this.isLoading = true;
    this.customerPageService
      .getCustomerPageText(page, pageCase, subCase)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.customerPageTextsFormControls.formGroup.patchValue(data);
          const textArray = data && data.textFieldLanguages;
          this.customerPageTextsFormControls.formGroup.controls.textFieldLanguage.clear();
          if (data) {
            textArray &&
              textArray.forEach((textField) => {
                const textFieldFormGroup =
                  this.customerPageTextsFormControls.constructTextFieldsDataFormGroup(textField);
                this.customerPageTextsFormControls.formGroup.controls.textFieldLanguage.push(textFieldFormGroup);
              });
          } else {
            this.languagesIds.forEach((newLang) => {
              this.customerPageTextsFormControls.formGroup.controls.textFieldLanguage.push(
                this.customerPageTextsFormControls.constructLangForTextFieldsDataFormGroup(newLang),
              );
            });
          }
        },
      });
  }

  getSystemLanguage() {
    this.systemConfigurationService
      .getLanguageSystemConfiguration()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((languageConfiguration) => {
        this.systemLanguageConfiguration = languageConfiguration;
      });
  }
}
