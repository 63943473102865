import { inject, Injectable } from "@angular/core";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { ITextFieldGroup } from "@root/views/main/property/property-marketing/components/text-field-form-group.interface";

@Injectable()
export class PropertyTextsFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #internalTitleControl = this.#fb.control<string | undefined>(undefined, Validators.required);
  readonly #isActiveControl = this.#fb.control<boolean>(false);
  readonly #placementId = this.#fb.control<number | null>(null, Validators.required);
  readonly #allowDelinkingOnUnitLevel = this.#fb.control<boolean>(false);
  textFieldsFormArray = this.#fb.array<FormGroup<ITextFieldGroup>>([]);

  firstStepGroup = this.#fb.group({
    internalTitle: this.#internalTitleControl,
    isActive: this.#isActiveControl,
    allowDelinkingOnUnitLevel: this.#allowDelinkingOnUnitLevel,
    placementId: this.#placementId,
  });

  secondStepGroup = this.#fb.group({
    textFields: this.textFieldsFormArray,
  });

  formGroup = this.#fb.group({
    internalTitle: this.#internalTitleControl,
    isActive: this.#isActiveControl,
    placementId: this.#placementId,
    allowDelinkingOnUnitLevel: this.#allowDelinkingOnUnitLevel,
    textFieldLanguages: this.textFieldsFormArray,
  });
}
