import { Component, OnInit } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { TranslateModule } from "@ngx-translate/core";
import { TextPageAbstract } from "@root/shared/abstracts/text-page/text-page.abstract";
import { CustomerPageTextEditorComponent } from "@root/shared/accordion-editor/accordion-editor.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { CustomerPageTextsFormControls } from "../customer-page-texts.form-controls";

@Component({
  selector: "est-welcome-page",
  standalone: true,
  imports: [
    MatAccordion,
    SmallHeaderComponent,
    TranslateModule,
    SmallHeaderComponent,
    CustomerPageTextEditorComponent,
    TranslateModule,
  ],
  templateUrl: "./welcome-page.component.html",
  styleUrl: "./welcome-page.component.scss",
  providers: [CustomerPageTextsFormControls],
})
export class WelcomePageComponent extends TextPageAbstract implements OnInit {
  ngOnInit(): void {
    this.getSystemLanguage();
  }
}
