import { inject, Injectable } from "@angular/core";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { ITextFieldLanguage } from "@root/data/market/properties/models/text-field-language.interface";
import { TextEditorImageFormGroup } from "@root/shared/interfaces/text-editor.interface";
import { constructTextEditorFormGroup } from "@root/shared/utilities/text-editor.utilties";
import { ITextFieldGroup } from "@root/views/main/property/property-marketing/components/text-field-form-group.interface";

@Injectable()
export class CustomerPageTextsFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  textFieldsFormArray = this.#fb.array<FormGroup<ITextFieldGroup>>([]);

  formGroup = this.#fb.group({
    page: this.#fb.control<string>(""),
    case: this.#fb.control<string>(""),
    subCase: this.#fb.control<string>(""),
    textFieldLanguage: this.textFieldsFormArray,
  });

  constructTextFieldsDataFormGroup(data: ITextFieldLanguage): FormGroup<ITextFieldGroup> {
    return this.#fb.group(
      {
        isEnabled: this.#fb.control<boolean>(false),
        isHeadlineEnabled: this.#fb.control<boolean>(false),
        languageId: this.#fb.control<string>(data.languageId),
        headline: this.#fb.control<string>(data.headline, Validators.required),
        text: this.constructTextEditorDataFormGroup(data),
      },
      { updateOn: "change" },
    );
  }
  constructLangForTextFieldsDataFormGroup(langId: string): FormGroup<ITextFieldGroup> {
    return this.#fb.group(
      {
        isEnabled: this.#fb.control<boolean>(false),
        isHeadlineEnabled: this.#fb.control<boolean>(false),
        languageId: this.#fb.control<string>(langId),
        headline: this.#fb.control<string>("", Validators.required),
        text: constructTextEditorFormGroup(this.#fb),
      },
      { updateOn: "change" },
    );
  }
  constructTextEditorDataFormGroup(data: ITextFieldLanguage) {
    return this.#fb.group(
      {
        value: this.#fb.control<string>(data.text.value!),
        images: this.#fb.array<FormGroup<TextEditorImageFormGroup>>([]),
      },
      { updateOn: "change" },
    );
  }

  constructUserDivisionRoleGroup(): FormGroup<ITextFieldGroup> {
    return this.#fb.group<ITextFieldGroup>(
      {
        isEnabled: this.#fb.control<boolean>(false),
        isHeadlineEnabled: this.#fb.control<boolean>(false),
        languageId: this.#fb.control<string>(""),
        headline: this.#fb.control<string>("", Validators.required),
        text: constructTextEditorFormGroup(this.#fb),
      },
      { updateOn: "change" },
    );
  }
}
