export enum CustomerPageCase {
  ConfirmAppointment = "ConfirmAppointment",
  ReconfirmAppointment = "ReconfirmAppointment",
  ConfirmInterest = "ConfirmInterest",
  NotInterested = "NotInterested",
  CancelAppointment = "CancelAppointment",
}
export const PageCaseLabels = new Map<CustomerPageCase, string>([
  [CustomerPageCase.ConfirmAppointment, "CONFIRM_APPOINTMENT"],
  [CustomerPageCase.ReconfirmAppointment, "TEXT_PAGE.WELCOME_PAGE.RE_CONFIRM_APPOINTMENT"],
  [CustomerPageCase.ConfirmInterest, "TEXT_PAGE.WELCOME_PAGE.CONFIRM_INTEREST"],
  [CustomerPageCase.NotInterested, "TEXT_PAGE.WELCOME_PAGE.NOT_INTERESTED"],
  [CustomerPageCase.CancelAppointment, "CANCEL_APPOINTMENT"],
]);
